
import axios, { AxiosError } from 'axios';
import { defineComponent, ref, watch, onBeforeMount, Ref } from 'vue';
import useAlert from "@/composables/Alert";
import {
  CampHeader,
  CampTable,
  CampTableTd,
  CampTableTh,
  CampEmptyListFeedbackV2,
  CampFooterPaginationControl,
  CampBtnGenerateExcel,
} from '@/components';
import { useLoaderStore } from "@/store/LoaderStore";
import moment from 'moment';
import { ptBr } from 'element-plus/es/locale';
import { ICompanyData } from '@/store/CompaniesStore';
import { IUserData } from '@/store/UsersStore';
import { campGoToTopByScrollMode } from '@/composables/Helpers';
import { ICompanyResponse } from '@/views/Companies/ListPage.vue';
import { IUserResponse } from '@/views/Users/ListPage.vue';
import { campHandleCNPJ, campRemoveLastNonAlphanumericCharacters } from '@/composables/DataValidation';

type TOperationType = 'auto' | 'manual' | 'custom'

type TFeedBack = 'new-entry' | 'entry-canceled' | 'entry-exists' | 'prod-not-listed' | 'gift'

interface IReturn1 {
  desc: string
  sku: string | number
  cod: string | number
  qtd: string | number
}

interface IReturn2 {
  code: string | number
  items: IReturn1[]
}

interface IReportInvoices {
  id: number,
  tableId: number | number,
  storageURL: string,
  operationType: TOperationType,
  companyCNPJ: string,
  companyName: string,
  created_at: string,
  movementId: string,
  ps: string,
  return: string,
  statusHeader: string | number,
  storeCNPJ: string,
  storeName: string,
  userCPF: string,
  userName: string,
}

interface IReportInvoicesResponse {
  data: {
    data: {
      data: IReportInvoices[],
      current_page: number,
      from: number,
      last_page: number,
      per_page: number,
      to: number,
      total: number,
    },
    error: boolean,
    message: string,
    errorCode: string,
  }
}

type TParam = {
  key: string | number;
  label: string;
}

type TSearchFields = {
  oldest?: number | boolean
  date?: string[] | null
  company?: number[]
  store?:number[]
  user?: number[]
  movementId?: string
  ps?: string
  feedback?: string
  operationType?: string[]
}

type TSearchParams = {
  page: number | null
  oldest: boolean | number | null
  start_date: string | null
  end_date: string | null
  company_id_list: number[] | null
  store_id_list: number[] | null
  user_id_list: number[] | null
  movement_id: string | null
  ps: string | null
  feedback: string | null
  operation_type: string[] | null
}

interface IReportInvoicesTable {
  tableId: string | number
  companyName: string
  companyCNPJ: string
  storeName: string
  storeCNPJ: string
  userResponsible: string
  type: string
  movementId: string
  created_at: string,
  desc: string
  sku: string | number
  cod: string | number
  qtd: string | number
  XMLFileURL: string | null
  ps: string
}

const INIT_SEARCH_PARAMS:TSearchParams = {
  page: null,
  oldest: null,
  start_date: null,
  end_date: null,
  company_id_list: null,
  store_id_list: null,
  user_id_list: null,
  movement_id: null,
  ps: null,
  feedback: null,
  operation_type: null
}

const OLDEST_LIST: TParam[] = [
  { key: 0, label: "Mais recentes" },
  { key: 1, label: "Mais antigos" },
]

const FEEDBACK_LIST: TParam[] = [
  { key: "new-entry", label: "Entrada de Produto no Estoque" },
  { key: "entry-canceled", label: "Cancelamento de Nota" },
  { key: "entry-exists", label: "Já deu entrada em estoque" },
  { key: "prod-not-listed", label: "Produto não cadastrado" },
  { key: "gift", label: "Brinde" },
]

const OPERATION_TYPE_LIST: TParam[] = [
  { key: "auto", label: "Integração" },
  { key: "manual", label: "Arquivo XML" },
  { key: "custom", label: "Entrada por item" },
]

const handlerError = (error: AxiosError | unknown, showTimeAlert: Function) => {
  console.error(error)
  if(error instanceof AxiosError)
    showTimeAlert(error.response?.data.message, "error")
  else
    showTimeAlert("Algo deu errado!", "error")
}

const handleSearchParams = (fields: Ref<TSearchFields>, params: Ref<TSearchParams>, page: number) => {
  params.value = { ...INIT_SEARCH_PARAMS }

  params.value.page = page

  if(Array.isArray(fields.value.company) && fields.value.company.length)
    params.value.company_id_list = fields.value.company

  if(Array.isArray(fields.value.store) && fields.value.store.length)
    params.value.store_id_list = fields.value.store

  if(Array.isArray(fields.value.user) && fields.value.user.length)
    params.value.user_id_list = fields.value.user

  if(typeof fields.value.movementId === "string")
    params.value.movement_id = fields.value.movementId

  if(typeof fields.value.ps === "string")
    params.value.ps = fields.value.ps

  if(Array.isArray(fields.value.operationType) && fields.value.operationType.length)
    params.value.operation_type = fields.value.operationType

  if(typeof fields.value.feedback === "string")
    params.value.feedback = fields.value.feedback

  if(Array.isArray(fields.value.date) && fields.value.date.length > 1) {
    params.value.start_date = moment(fields.value.date[0]).startOf('day').format('YYYY-MM-DD HH:mm:ss')
    params.value.end_date = moment(fields.value.date[1]).endOf('day').format('YYYY-MM-DD HH:mm:ss')
  }

  if(typeof fields.value.oldest === "number")
    params.value.oldest = fields.value.oldest
}

const handleSearchFields = (params: Ref<TSearchParams>, fields: Ref<TSearchFields>, page: number) => {
  params.value.page = page

  fields.value = new Object()

  if(Array.isArray(params.value.company_id_list) && params.value.company_id_list.length)
    fields.value.company = params.value.company_id_list

  if(Array.isArray(params.value.store_id_list) && params.value.store_id_list.length)
    fields.value.store = params.value.store_id_list

  if(Array.isArray(params.value.user_id_list) && params.value.user_id_list.length)
    fields.value.user = params.value.user_id_list

  if(typeof params.value.movement_id === "string")
    fields.value.movementId = params.value.movement_id

  if(typeof params.value.ps === "string")
    fields.value.ps = params.value.ps

  if(Array.isArray(params.value.operation_type) && params.value.operation_type.length)
    fields.value.operationType = params.value.operation_type

  if(typeof params.value.feedback === "string")
    fields.value.feedback = params.value.feedback

  if(typeof params.value.start_date === "string" && typeof params.value.end_date === "string")
    fields.value.date = [params.value.start_date, params.value.end_date]

  if(typeof params.value.oldest === "number")
    fields.value.oldest = params.value.oldest
}

const handleOperationType = (char: TOperationType) => {
  switch(char) {
    case 'auto':
      return 'Integração'

    case 'manual':
      return 'Arquivo XML'
    
    case 'custom':
      return 'Entrada por item'
  
    default:
      return '-'
  }
}

const handleListAccordingToJSON = (data: IReportInvoices[]):IReportInvoicesTable[] => {
  const dataPrint:IReportInvoicesTable[] = new Array()
  data.forEach((report: IReportInvoices) => {
    const obj:IReportInvoicesTable = {
      tableId: `key-${report.id}`,
      companyName: report.companyName || '-',
      companyCNPJ: report.companyCNPJ || '-',
      storeName: report.storeName || '-',
      storeCNPJ: report.storeCNPJ || '-',
      userResponsible: report.userName || '-',
      type: handleOperationType(report.operationType),
      movementId: report.movementId || '-',
      created_at: moment(report.created_at).format("DD/MM/YYYY HH:mm:ss") || "-",
      desc: '-',
      sku: '-',
      cod: '-',
      qtd: '-',
      XMLFileURL: report.storageURL || '-',
      ps: report.ps || '-',
    }
    try {
      const jsonParsed: IReturn1 | IReturn2 = JSON.parse(report.return)
      if('items' in jsonParsed && Array.isArray(jsonParsed.items) && jsonParsed.items.length) {
        jsonParsed.items.forEach((item, index) => {
          obj.tableId = `${obj.tableId}-${index}`
          obj.cod = item.cod
          obj.sku = item.sku
          obj.qtd = item.qtd
          obj.desc = item.desc
          dataPrint.push(JSON.parse(JSON.stringify(obj)))
        })
      } else if('sku' in jsonParsed) {
        obj.cod = jsonParsed.cod
        obj.sku = jsonParsed.sku
        obj.qtd = jsonParsed.qtd
        obj.desc = jsonParsed.desc
        dataPrint.push(JSON.parse(JSON.stringify(obj)))
      } else {
        dataPrint.push(JSON.parse(JSON.stringify(obj)))
      }
    } catch (error) {
      console.error(error)
      dataPrint.push(JSON.parse(JSON.stringify(obj)))
    }
  })
  return dataPrint
}

const INIT_PRINT_ITEMS:IReportInvoicesTable[] = [
  {
    tableId: '',
    companyName: '',
    companyCNPJ: '',
    storeName: '',
    storeCNPJ: '',
    userResponsible: '',
    type: '',
    movementId: '',
    created_at: '',
    cod: '',
    qtd: '',
    sku: '',
    desc: '',
    XMLFileURL: '',
    ps: '',
  }
]

const COLS_TABLE = {
  companyName: 'Companhia',
  companyCNPJ: 'CNPJ (Companhia)',
  storeName: 'Loja',
  storeCNPJ: 'CNPJ (Loja)',
  userResponsible: 'Responsável',
  type: 'Tipo da Operação',
  movementId: 'Nº da Nota',
  created_at: 'Data de Processamento',
  desc: 'Item',
  sku: 'SKU',
  cod: 'Cód. Produto',
  qtd: 'Quantidade',
  XMLFileURL: 'Arquivo XML',
  ps: 'Observação',
}

export default defineComponent({
  name: "ReportInvoices",
  components: {
    CampHeader,
    CampTable,
    CampTableTd,
    CampTableTh,
    CampEmptyListFeedbackV2,
    CampFooterPaginationControl,
    CampBtnGenerateExcel,
  },
  setup() {
    const { showTimeAlert } = useAlert()
    const loaderStore = useLoaderStore()
    const isLoading = ref(true)
    const indexCurrentPage = ref(1)
    const totalPage = ref(1)
    const companyList = ref<ICompanyData[] | null>(null)
    const storeList = ref<ICompanyData[] | null>(null)
    const userList = ref<IUserData[] | null>(null)
    const reportInvoiceList = ref<IReportInvoices[] | null>(null)
    const reportInvoicesTable = ref<IReportInvoicesTable[] | null>(null)
    const reportInvoicesTableRender = ref<IReportInvoicesTable[] | null>(null)
    const keywordSearch = ref("")
    const searchFields = ref<TSearchFields>({})
    const searchParams = ref<TSearchParams>({ ...INIT_SEARCH_PARAMS })

    /** Functions */
    async function getDatas() {
      try {
        isLoading.value = true
        type TResponseList = [ IReportInvoicesResponse, ICompanyResponse, ICompanyResponse, IUserResponse ]
        const [ logsResponse, companiesResponse, storesResponse, usersResponse ]: TResponseList = await Promise.all([
          axios.post('/api/generateNoteReportList'),
          axios.get('/api/getAllCompanies'),
          axios.get('/api/getAllStores'),
          axios.get('/api/getAllUsers')
        ]);
        reportInvoiceList.value = logsResponse.data.data.data.map(el => ({ ...el })) || null
        companyList.value = companiesResponse.data.data.map(el => ({ ...el, option: `${el.cnpj}-${el.fantasy_name}` })) || null
        storeList.value = storesResponse.data.data.map(el => ({ ...el, option: `${el.cnpj}-${el.fantasy_name}` })) || null
        userList.value = usersResponse.data.data.map(el => ({ ...el, option: `${el.name}` })) || null
        indexCurrentPage.value = 1
        totalPage.value = logsResponse.data.data.last_page
        reportInvoicesTable.value = new Array()
        if(Array.isArray(reportInvoiceList.value) && reportInvoiceList.value.length)
          reportInvoicesTable.value = handleListAccordingToJSON(reportInvoiceList.value)
      } catch (error) {
        handlerError(error, showTimeAlert)
      } finally {
        isLoading.value = false
      }
    }

    function handleFilterObjectsByKeyword(): boolean {
      if(!reportInvoicesTable.value)
        return true
      reportInvoicesTableRender.value = reportInvoicesTable.value.filter((item) => {
        for (const key in item) {
          const propValue = item[key];
          if (typeof propValue === 'string' && propValue.toLowerCase().includes(keywordSearch.value.toLowerCase()))
            return true;
          if (typeof propValue === 'number' && keywordSearch.toString() === propValue.toString())
            return true;
        }
        return false;
      });
      return false
    }

    async function handleSearch() {
      try {
        loaderStore.open()
        isLoading.value = true
        indexCurrentPage.value = 1
        handleSearchParams(searchFields, searchParams, indexCurrentPage.value)
        const response: IReportInvoicesResponse = await axios.post("/api/generateNoteReportList", searchParams.value)
        const { data } = response.data
        reportInvoiceList.value = data.data.map(el => ({ ...el })) || null
        reportInvoicesTable.value = new Array()
        if(Array.isArray(reportInvoiceList.value) && reportInvoiceList.value.length)
          reportInvoicesTable.value = handleListAccordingToJSON(reportInvoiceList.value)
        totalPage.value = response.data.data.last_page
      } catch (error) {
        handlerError(error, showTimeAlert)
      } finally {
        loaderStore.close()
        isLoading.value = false
      }
    }

    async function handlePaginationRendering(index: number) {
      try {
        loaderStore.open()
        indexCurrentPage.value = index
        handleSearchFields(searchParams, searchFields, index)
        const response: IReportInvoicesResponse = await axios.post("/api/generateNoteReportList", searchParams.value)
        const { data } = response.data
        reportInvoiceList.value = data.data.map(el => ({ ...el }))
        reportInvoicesTable.value = new Array()
        if(Array.isArray(reportInvoiceList.value) && reportInvoiceList.value.length)
          reportInvoicesTable.value = handleListAccordingToJSON(reportInvoiceList.value)
        campGoToTopByScrollMode(false)
      } catch (error) {
        handlerError(error, showTimeAlert)
      } finally {
        loaderStore.close()
      }
    }

    const updateReportInvoiceListRender = () => reportInvoicesTableRender.value = reportInvoicesTable.value?.map(el => ({ ...el })) || null

    /** Life Cycles */
    onBeforeMount(() => getDatas())

    watch(() => reportInvoiceList.value, () => {
      updateReportInvoiceListRender()
      handleFilterObjectsByKeyword()
    }, { deep: true })

    watch(() => keywordSearch.value, () => handleFilterObjectsByKeyword())

    return {
      moment,
      ptBr,
      isLoading,
      keywordSearch,
      reportInvoicesTableRender,
      companyList,
      storeList,
      userList,
      searchFields,
      OLDEST_LIST,
      FEEDBACK_LIST,
      OPERATION_TYPE_LIST,
      handleSearch,
      handlePaginationRendering,
      indexCurrentPage,
      totalPage,
      campHandleCNPJ,
      COLS_TABLE,
      INIT_PRINT_ITEMS,
      campRemoveLastNonAlphanumericCharacters,
    }
  }
})
